<template>
  <h1>Admin Dashboard</h1>  
</template>

<script>
export default {
  
}
</script>

<style lang="stylus" scoped>

</style>