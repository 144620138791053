<template>
  <v-btn icon
          @click="$router.go(-1)"
  >
    <v-icon>mdi-arrow-left</v-icon>
  </v-btn>
</template>

<script>
export default {

}
</script>

<style>

</style>